import { loginFace, signupFace } from "../../../shared/utils/interfaces/AuthInterface";
import { apiSlice } from "../../../shared/slices/apiSlice";
import { tagTypes } from '../../../shared/utils/constantData'
const { user, jobs } = tagTypes;
export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder: any) => ({
        login: builder.mutation({
            query: (initialLoginData: loginFace) => ({
                url: "/auth/login",
                method: "POST",
                body: initialLoginData,
            }),
            invalidatesTags: (result: any, error: any) => error || !result?.data?.isVerified || !result?.data?.haveJobs ? [] : [user, jobs]
        }),
        sendOtp: builder.mutation({
            query: (otpData: { email: string }) => ({
                url: "/auth/sendOtp",
                method: "POST",
                body: otpData,
            })
        }),
        verifyOtp: builder.mutation({
            query: (otpData: { email: string, otp: string }) => ({
                url: "/auth/verify-otp",
                method: "POST",
                body: otpData,
            })
        }),
        signup: builder.mutation({
            query: (initialSignupData: signupFace) => ({
                url: "/auth/signup",
                method: "POST",
                body: initialSignupData,
            }),
            invalidatesTags: (result: any, error: any) => !error && result?.data?.user?.invitationId ? [user] : []
        }),
        resetPassword: builder.mutation({
            query: (passwordData: { email: string, otp: string, newPassword: string }) => ({
                url: "/auth/reset-password",
                method: "POST",
                body: passwordData,
            }),
        }),
        getUserProfile: builder.query({
            query: () => 'users/profile',
            providesTags: [user]
        }),
        logout: builder.mutation({
            query: () => ({
                url: "/auth/logout",
                method: "POST"
            }),
            invalidatesTags: (_result: any, error: any) => error ? [] : [user]
        }),
        getPermissions: builder.query({
            query: () => '/users/permissions',
        }),
    }),
})
export const {
    useLoginMutation,
    useSignupMutation,
    useSendOtpMutation,
    useVerifyOtpMutation,
    useResetPasswordMutation,
    useGetUserProfileQuery,
    useLogoutMutation,
    useGetPermissionsQuery,
} = authApiSlice
