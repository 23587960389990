import { Empty } from 'antd';
import EmptyImage from '../../assets/images/noPublishJob.svg';

export const EmptyList = ({ title, description, className }: { title: string, description?: string, className?: string }) => {
    return (
        <Empty
            image={EmptyImage}
            className={`pt-7 mb-5 ${className ?? ''}`}
            imageStyle={{ display: 'flex', justifyContent: 'center' }}
            description={
                <>
                    <span className='text-gray-600 font-semibold text-xl leading-8'>
                        {title}
                    </span>
                    {description && <p className='text-gray-600 text-lg leading-6 font-normal'>{description}</p>
                    }
                </>
            }
        >
        </Empty>
    );
}