import { createSlice } from "@reduxjs/toolkit";

const tabsSlice = createSlice({
    name: 'tabs',
    initialState: { activeTab: '' },
    reducers: {
        setActiveTab: (state, action: { payload: string }) => {
            state.activeTab = action.payload
        }
    }
})

export const { setActiveTab } = tabsSlice.actions;

export default tabsSlice.reducer;

export const selectActiveTab = (state: { tabs: { activeTab: string } }) => state.tabs.activeTab