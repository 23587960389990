import { useEffect, useState } from "react";
import { Button, message, notification } from "antd";
import WhiteStar from '../../../shared/assets/images/whiteStar.svg';
import { baseUrl } from "../constantData";
import { IFileStatus } from "../interfaces/jobData";
import { useDispatch, useSelector } from "react-redux";
import { selectBatchId, setBatchId } from "../../../modules/createJob/slice/jobSlice";



const useBulkUploadStatus = () => {
    const reduxBatchId = useSelector(selectBatchId);
    const batchId = localStorage.getItem('batchId');
    const [status, setStatus] = useState<IFileStatus | null>(null);
    const [api, contextHolder] = notification.useNotification();
    const notificationKey = 'bulkUploadStatusNotification';
    const dispatch = useDispatch();

    useEffect(() => {
        if (batchId === null) {
            return;
        }

        let eventSource: EventSource;

        try {
            eventSource = new EventSource(`${baseUrl}applications/bulkUploadStatus/${batchId}`, { withCredentials: true });

            eventSource.onmessage = (event) => {
                try {
                    const newStatus = JSON.parse(event?.data) as IFileStatus;
                    const isEqual = newStatus?.fileCount === newStatus?.totalFiles;
                    setStatus(newStatus);
                    if (isEqual) {
                        eventSource.close();
                        localStorage.removeItem('batchId');
                        dispatch(setBatchId(null));
                    }

                    const notificationDescription = (
                        isEqual ?
                            <div className="pb-2.5 pl-4 pr-3.5">
                                <img className="inline mr-2.5" src={WhiteStar} alt="white Star" />
                                Resumes uploaded successfully
                                <Button
                                    type="primary"
                                    onClick={() => api.destroy(notificationKey)}
                                    style={{ marginLeft: '10px' }}
                                >
                                    Close
                                </Button>
                            </div> : newStatus?.status !== 200 ?
                                <div className="pb-2.5 pl-4 pr-3.5 bg-red-700">
                                    <img className="inline mr-2.5" src={WhiteStar} alt="white Star" />
                                    Resumes upload failed
                                    <Button
                                        type="primary"
                                        onClick={() => api.destroy(notificationKey)}
                                        style={{ marginLeft: '10px' }}
                                    >
                                        Close
                                    </Button>
                                </div>
                                : <div className="pb-2.5 pl-4 pr-3.5">
                                    <img className="inline mr-2.5" src={WhiteStar} alt="white Star" />
                                    Analyzing uploaded resume{newStatus.totalFiles > 1 ? 's' : ''} <span className="ml-2.5 font-semibold">{newStatus.fileCount} of {newStatus.totalFiles}</span>
                                </div>
                    );

                    api.open({
                        key: notificationKey,
                        message: '',
                        description: notificationDescription,
                        placement: 'bottomRight',
                        style: { background: `${(newStatus?.status !== 200 && !isEqual) && '#c81e1e'}` },
                        duration: null,
                    });
                } catch (e) {
                    console.error('Failed to parse event data:', e);
                    eventSource.close();
                    message.error('Something failed with the uploading')
                }
            };

            eventSource.onerror = (error) => {
                console.error('EventSource failed:', error);
                eventSource.close();
            };
        } catch (e) {
            console.error('Failed to initialize EventSource:', e);
            message.error('Something failed with the uploading')

        }

        return () => {
            if (eventSource) {
                eventSource.close();
            }
        };
    }, [api, batchId, reduxBatchId, dispatch]);

    return { status, contextHolder };
};

export default useBulkUploadStatus;
