import './shared/scss/style.scss'
import { routes } from './Routes';
import { ConfigProvider } from 'antd';
import { antdThemeTokenConfig } from './antdConfig';
import 'dayjs/locale/zh-cn';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useGetPermissionsQuery, useGetUserProfileQuery } from './modules/authentication/slice/createAuthApiSlice';
import { setCredentials, setAllRolesPermissions, setRolePerCompany } from './modules/authentication/slice/authSlice';
import { useEffect } from 'react';
import { apiStatuses } from './shared/utils/constantData';
import { Warning } from './shared/components/Warning/Warning';
import { setCompanyData } from './modules/createJob/slice/companySlice';
import { getImageUrl } from './shared/utils/functions/images';
import FacebookPixel from './shared/utils/tracking/FacebookPixel'; // ENABLE ON PROD ONLY

declare global {
  interface Window {
    pagesense?: Array<Array<string | unknown>>;
  }
}
function App() {
  const [cookies, setCookie] = useCookies(['isActive'])
  const { isActive } = cookies
  const dispatch = useDispatch();
  const { data: user, isLoading, isSuccess, isError, error } = useGetUserProfileQuery();
  const { data, isSuccess: isPermissionsSuccess, isError: hasPermissionsError } = useGetPermissionsQuery(undefined, { skip: (isError && !isActive) || isLoading });

  // ENABLE ON PROD from here
  useEffect(() => {
    console.log = function () { };
    if (!user) {
      return
    }
    const script = document.createElement('script');
    // script.src = "https://cdn.pagesense.io/js/nowpay/a47dc19c08a34259bca04370b4104a4a.js"; // Dev
    script.src = "https://cdn.pagesense.io/js/nowpay/42a6d75f0f28471aa9224ded3fa3bbba.js"; // Prod
    script.async = true;
    document.head.appendChild(script);
    const identifyUser = `${user?.email} ${user?.id}`

    // Call Zoho PageSense identifyUser function

    window.pagesense = window.pagesense || [];
    window.pagesense.push(['identifyUser', identifyUser ?? 'Unauthorized user']);
    // Cleanup function
    return () => {
      document.head.removeChild(script);
    };
  }, [user]);
  // To here

  useEffect(() => {
    if (isSuccess) {
      dispatch(setCredentials({ user }))
      dispatch(setCompanyData(user?.company))
      setCookie('isActive', true, { path: '/' })

    }
    if (error?.data?.statusCode === apiStatuses.unAuthorized) {
      dispatch(setCredentials({ user: null }))
      setCookie('isActive', false, { path: '/' })
    }
  }, [isSuccess, isError, user, dispatch, error, setCookie])

  useEffect(() => {
    if (isPermissionsSuccess) dispatch(setAllRolesPermissions(data?.rolesPermissions))
    dispatch(setRolePerCompany(user?.adminRole ?? null));
    // eslint-disable-next-line
  }, [dispatch, isPermissionsSuccess, data, user])
  return (
    <>
      <ConfigProvider theme={{ token: antdThemeTokenConfig }}>
        <FacebookPixel /> {/* ENABLE ON PROD ONLY */}
        {(error?.data?.statusCode !== apiStatuses.unAuthorized && isError) || hasPermissionsError ?
          <div className='wrapper'>
            <div className='mt-12'>
              <Warning icon={<img alt="Warning!" src={getImageUrl('warningGray')} />} title='Something went wrong!' message='Please refresh to try again' btnText='Refresh' />
            </div>
          </div>
          : isSuccess || error?.data?.statusCode === apiStatuses.unAuthorized ?
            <div className={`relative min-h-screen App`}>
              {routes}
            </div> : null}
      </ConfigProvider>
    </>
  );
}

export default App;
