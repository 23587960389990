import { RoleType } from "./AuthInterface";
import { tabs } from "./createJobTabs";
import { IPipeline } from "./pipelineInterface";
export interface IHiringStage {
    id: number;
    name: tabs;
    count: string;
}
export interface ICandidate {
    id: number
    firstName: string
    lastName: string
    email: string
    phone: number
    cv: string
    coverLetter: string | null
    cvScore: number | null
    cvSummary: string | null
    longSummary: string | null
    comments: []
    pipelineStages: IPipeline[]
    pipelineHiringStageId: number,
    numberOfComments: number,
    jobId: number,
    interviewQuestions: string[]
    strengths: string[],
    weaknesses: string[],
    applicationCriteriasEvaluation: ICriteria[],
    batchId: number,
    cvFileName: string
}
export interface ICriteria {
    applicationId: number
    id: number
    jobCriteria: {
        criteriaName: string
        id: number
        isMain: boolean
        jobId: number
    }
    jobCriteriaId: number
    score: string
    summary: string,
    longSummary: string | null,
}

export interface ICandidatesFilter {
    page: number,
    perPage: number,
    sortBy: string,
    hiringStageId: number
}
export const candidatesSorting = [
    {
        value: 'highestScore',
        label: 'Sort by Highest Score'
    },
    {
        value: 'mostRecent',
        label: 'Sort by Most Recent'
    },
    {
        value: 'az',
        label: 'Sort by A-Z'
    }
]

export interface IHiringStageModi {
    label: string;
    key: number;
    disabled: boolean,
    danger?: boolean
}

export interface IChangeCandidateStage {
    applicationId: number;
    hiringStageId: number;
}

export interface IInvitationData {
    email: string,
    roleId: number,
    objectId: number, // in case job >> objectId will be jobId , in case company  >> objectId will be companyId
    ObjectType: RoleType,
    isTopLevel: boolean,
    id: number
}

export interface IUpdateUserRoleBody {
    userId: number,
    roleId: number,
    objectId: number
    objectType: RoleType
}

export enum currentTypes {
    CV = "CV",
    coverLetter = "COVER_LETTER",
    Graph = "GRAPH"
}
export interface IAiSearchBody {
    jobId: string | undefined,
    hiringStageId: number,
    query: string,
    searchMethodId: number,
    perPage?: number,
    page?: number
}
export interface IAIFilter {
    searchMethodId: number,
    page: number,
    perPage: number,
}