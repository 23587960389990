import { apiSlice } from "../../../shared/slices/apiSlice";
import { IAiSearchBody, IChangeCandidateStage, IInvitationData, IUpdateUserRoleBody } from "../../../shared/utils/interfaces/candidatesListFace";
import { tagTypes } from "../../../shared/utils/constantData";
import { IAddComment } from "../../../shared/utils/interfaces/commentsFaces";
import { IEmailBody } from "../../../shared/utils/interfaces/apisInterfaces";
const { job, candidates, members, comments, aiSearch ,templates} = tagTypes
export const candidateApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder: any) => ({
        getCandidatesByJobId: builder.query({
            query: (args: any) => {
                const { jobId, page, perPage, sortBy, hiringStageId } = args;
                return {
                    url: `/applications/job/${jobId}`,
                    params: { page, perPage, sortBy, hiringStageId },
                };
            },
            providesTags: [candidates]
        }),
        getCandidateById: builder.query({
            query: (candidateId: number) => ({
                url: `/applications/${candidateId}`
            })
        }),
        changeCandidateStage: builder.mutation({
            query: (initialActionsData: IChangeCandidateStage) => ({
                url: '/applications',
                method: 'PATCH',
                body: initialActionsData
            }),
            invalidatesTags: (_result: any, error: any) => error ? [] : localStorage.getItem("activeAiFilter") ? [job, aiSearch] : [job, candidates]
        }),
        updateHiringPipeline: builder.mutation({
            query: (hiringStageData: {
                jobId: number,
                hiringStagesOrder: number[]
            }) => ({
                url: "/jobs/pipeline",
                method: "PATCH",
                body: hiringStageData,
            }),
            invalidatesTags: (_result: any, error: any) => error ? [] : [job, candidates]
        }),
        getMembers: builder.query({
            query: (jobId: number) => `/users?jobId=${jobId}`,
            providesTags: [members]
        }),
        getMembersRoles: builder.query({
            query: () => `/users/roles`,
        }),
        inviteNewUser: builder.mutation({
            query: (invitationData: IInvitationData) => ({
                url: "/users/invitation",
                method: "POST",
                body: invitationData,
            }),
            invalidatesTags: (_result: any, error: any) => error ? [] : [members]
        }),
        updateUserRole: builder.mutation({
            query: (updateRoleData: IUpdateUserRoleBody) => ({
                url: '/users/roles',
                method: "PATCH",
                body: updateRoleData,
            }),
            invalidatesTags: (_result: any, error: any) => error ? [] : [members]
        }),
        removeUserRole: builder.mutation({
            query: (updateRoleData: IUpdateUserRoleBody) => ({
                url: '/users/roles',
                method: "DELETE",
                body: updateRoleData,
            }),
            invalidatesTags: (_result: any, error: any) => error ? [] : [members]
        }),
        checkMemberInvitation: builder.query({
            query: (args: any) => {
                const { invitationId } = args;
                return {
                    url: `/users/invitation/check`,
                    params: { invitationId },
                };
            },
        }),
        getComments: builder.query({
            query: (candidateId: number) => ({
                url: `/applications/${candidateId}/comment`
            }),
            providesTags: [comments]
        })
        ,
        addNewComment: builder.mutation({
            query: (commentData: IAddComment) => ({
                url: "/applications/comment",
                method: "POST",
                body: commentData
            }),
            invalidatesTags: (_result: any, error: any) => error ? [] : [comments]
        }),
        getInterviewsQuestionsByCandidateId: builder.query({
            query: (candidateId: number) => ({
                url: `/applications/${candidateId}/questions`
            }),
        }),
        getCandidatesByIds: builder.query({
            query: (candidatesIds: string) => ({
                url: `/applications?ids=${candidatesIds}`
            })
        }),
        getCandidatesByAiSearch: builder.query({
            query: (args: IAiSearchBody) => {
                const { jobId, query, page, perPage, searchMethodId, hiringStageId } = args;
                return {
                    url: `/applications/aiSearch`,
                    params: { query, page, perPage, searchMethodId, hiringStageId, jobId },
                };
            },
            providesTags: [aiSearch]
        }),
        getTemplatesByApplicationID: builder.query({
            query: (applicationId: number) => ({
                url: `companies/emails/templates`,
                params: { applicationId }
            }),
            providesTags: [templates]
        }),
        sendEmail: builder.mutation({
            query: (emailBody: IEmailBody) => ({
                url: "/users/emails",
                method: "POST",
                body: emailBody
            })
        }),
    })
})
export const { useGetCandidatesByJobIdQuery, useGetCandidateByIdQuery, useChangeCandidateStageMutation, useUpdateHiringPipelineMutation,
    useGetMembersQuery, useGetMembersRolesQuery, useInviteNewUserMutation, useCheckMemberInvitationQuery, useGetCommentsQuery, useAddNewCommentMutation, useUpdateUserRoleMutation, useRemoveUserRoleMutation, useGetInterviewsQuestionsByCandidateIdQuery, useGetCandidatesByIdsQuery, useGetCandidatesByAiSearchQuery, useGetTemplatesByApplicationIDQuery, useSendEmailMutation } = candidateApiSlice
