export interface settingsLayoutFace {
    title: string,
    subtitle: string | React.ReactNode,
    children: React.ReactNode
}

export interface subFace {
    id: number,
    name: string
}

export enum settingsTabs {
    profile = 'profile',
    templates = 'templates',
    pipelines = 'pipelines',
    linkDomain = 'link-domain',
    emailIntegration = "email-integration"
}


export interface ItemplateAttributes {
    field: string,
    isInput: boolean
}