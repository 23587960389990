import { createSlice } from "@reduxjs/toolkit";
import { IRePortsFilter, pipelineJob } from "../../../shared/utils/interfaces/reports";

const reportsSlice = createSlice({
    name: 'reports',
    initialState: {
        activeCategoryText: '',
        isErrorJobs: false,
        firstJobId: '',
        pipeLineChartJobs: [],
        filters: {
            page: 1,
            perPage: 10,
        },
        reportsApiLink: '',
    },
    reducers: {
        setResetReports: (state) => {
            state.activeCategoryText = ''
            state.isErrorJobs = false
            state.firstJobId = ''
            state.pipeLineChartJobs = []
            state.filters = {
                page: 1,
                perPage: 10,
            }
            state.reportsApiLink = ''
        },
        setIsErrorJobs: (state, action) => {
            state.isErrorJobs = action.payload
        },
        setFirstJobId: (state, action) => {
            state.firstJobId = action.payload
        },
        setReportsFilters: (state, action) => {
            state.filters = action.payload
        },
        setPipeLineChartJobs: (state, action) => {
            state.pipeLineChartJobs = action.payload
        },
        setActiveCategoryText: (state, action) => {
            state.activeCategoryText = action.payload
        },
        setReportsApiLink: (state, action) => {
            state.reportsApiLink = action.payload
        },
    }
})

export const { setResetReports, setIsErrorJobs, setFirstJobId, setPipeLineChartJobs, setReportsFilters,
    setActiveCategoryText, setReportsApiLink } = reportsSlice.actions;

export default reportsSlice.reducer

export const selectIsErrorJobs = (state: { reports: { isErrorJobs: boolean } }) => state.reports.isErrorJobs;
export const selectFirstJobId = (state: { reports: { firstJobId: number } }) => state.reports.firstJobId;
export const selectPipeLineChartJobs = (state: { reports: { pipeLineChartJobs: pipelineJob[] } }) => state.reports.pipeLineChartJobs;
export const selectReportsFilters = (state: { reports: { filters: IRePortsFilter } }) => state.reports.filters
export const selectActiveCategoryText = (state: { reports: { activeCategoryText: string } }) => state.reports.activeCategoryText;
export const selectReportsApiLink = (state: { reports: { reportsApiLink: string } }) => state.reports.reportsApiLink;
