import { useEffect, useMemo, useState } from 'react'
import { useChangeCandidateStageMutation } from '../../slice/candiddateApiSlice';
import { permissionsActions } from '../../../../shared/utils/interfaces/AuthInterface';
import { useSelector } from 'react-redux';
import { selectCurrentHiringStages } from '../../slice/candidateSlice';
import { IHiringStage, IHiringStageModi } from '../../../../shared/utils/interfaces/candidatesListFace';
import { Button, Dropdown, MenuProps, Tag, message } from 'antd';
import { errorMessage, roleError } from '../../../../shared/utils/constantData';
import { convertToSent } from '../../../../shared/utils/functions/convertToSentWord';
import { analytics } from '../../../../shared/utils/analytics';
import { logEvent } from 'firebase/analytics';
import { canAccess } from '../../../../shared/utils/functions/checkPermission';
import { WithTooltip } from '../../../../shared/components/tooltip.tsx/withTootip';
import { getImageUrl } from '../../../../shared/utils/functions/images';
import { tabs } from '../../../../shared/utils/interfaces/createJobTabs';

function ChangeStageActions({ candidateId, hiringStageId }: { candidateId: number, hiringStageId: number }) {
    const { updateJobHiringStage } = permissionsActions
    const [changeCandidateStage, { isLoading, isSuccess, isError }] = useChangeCandidateStageMutation();
    const [isChangingStage, setIsChangingStage] = useState(false);
    const [nextStage, setNextStage] = useState<string | false>('');
    const [nextStageId, setNextStageId] = useState<number | false>(0);
    const [isLastStage, setIsLastStage] = useState(false)
    const [lastCandidateStageLabel, setLastCandidateStageLabel] = useState<string | false>('');
    const [selectedStageKey, setSelectedStageKey] = useState<any>(0);
    const [selectedStageLabel, setSelectedStageLabel] = useState<string | false>('')
    const currentStages: IHiringStage[] = useSelector(selectCurrentHiringStages);
    const currentStagesModi: IHiringStageModi[] | undefined = useMemo(() => {
        return currentStages?.length !== 0 &&
            Array.isArray(currentStages) ? [...currentStages.map((item: IHiringStage) => ({
                label: `Send to ${item?.name.charAt(0).toUpperCase() + item.name.slice(1)}`,
                key: item?.id,
                disabled: item?.id === hiringStageId,
                danger: item?.name === tabs.rejected 
            }))] : undefined;
    }, [hiringStageId, currentStages])
    useEffect(() => {
        const lastIndex = Array.isArray(currentStagesModi) && currentStagesModi.length - 1;

        let indexOfCurrentStage: number | false = Array.isArray(currentStagesModi) && currentStagesModi.findIndex(stage => stage.key === hiringStageId);
        let selectedStage: IHiringStageModi | undefined = currentStagesModi?.find(stage => stage.key === selectedStageKey);
        if (hiringStageId === 0) {
            setNextStage(Array.isArray(currentStagesModi) && currentStagesModi[hiringStageId]?.label)
            setNextStageId(Array.isArray(currentStagesModi) && currentStagesModi[hiringStageId]?.key)

        } else if (indexOfCurrentStage !== false && lastIndex === indexOfCurrentStage) {
            setIsLastStage(true);
            setLastCandidateStageLabel(convertToSent(Array.isArray(currentStagesModi) && currentStagesModi[indexOfCurrentStage]?.label)
            )
        } else if (indexOfCurrentStage !== false) {
            setIsLastStage(false);
            setNextStage(Array.isArray(currentStagesModi) && currentStagesModi[indexOfCurrentStage + 1]?.label);
            setNextStageId(Array.isArray(currentStagesModi) && currentStagesModi[indexOfCurrentStage + 1]?.key)
        }

        if (isSuccess) {
            setSelectedStageLabel(convertToSent(selectedStage?.label))
            setIsChangingStage(true)
            logEvent(analytics, 'Change candidate stage from candidate profile')
        }
        if (isError) {
            message.error(`${errorMessage}, the hiring stage is not updated`)
        }
    }, [currentStagesModi, hiringStageId, isSuccess, selectedStageKey, isError]);
    const handleCheckedHiringStage = async () => {
        const candidateData = {
            applicationId: Number(candidateId),
            pipelineHiringStageId: nextStageId,
        }
        setSelectedStageKey(nextStageId)
        await changeCandidateStage(candidateData)
    };

    const handleChangeHiringStage: MenuProps['onClick'] = async (e) => {
        const candidateData = {
            applicationId: Number(candidateId),
            pipelineHiringStageId: Number(e?.key)
        }
        setSelectedStageKey(candidateData.pipelineHiringStageId)
        await changeCandidateStage(candidateData)
    };
    return (
        <>
            {currentStagesModi && (
                <WithTooltip
                    visible={!canAccess(updateJobHiringStage)}
                    placement='bottom'
                    title={roleError}
                >
                    <div className="flex items-center w-auto my-auto ml-auto flex-nowrap group min-w-max h-fit">
                        {!isChangingStage ?
                            <>
                                {isLastStage ? <Tag className='ant-tag-info-ghost tag-lg me-0 min-w-max'>{lastCandidateStageLabel && lastCandidateStageLabel}<img src={getImageUrl('blueCheck')} className='inline ml-2 btn-icon' alt='arrow' /></Tag> :
                                    <Button
                                        loading={isLoading}
                                        disabled={!canAccess(updateJobHiringStage)}
                                        className='btn-primary-light !rounded-l-md !rounded-br-none !rounded-tr-none disabled:bg-transparent' onClick={handleCheckedHiringStage}>
                                        {isLastStage ? lastCandidateStageLabel : nextStage}
                                    </Button>}
                                <Dropdown
                                    className={`mr-0 cursor-pointer rounded-r-md p-2 ${!canAccess(updateJobHiringStage) ? '!bg-transparent border-r border-y !border-y-indigo-200 !border-r-indigo-200 !cursor-not-allowed' : ''} btn-primary-light border-l !border-l-indigo-200 shadow-sm`}
                                    trigger={['click']}
                                    disabled={!canAccess(updateJobHiringStage)}
                                    dropdownRender={menu => (
                                        <div className="overflow-y-auto bg-white border border-gray-300 rounded-md shadow-md max-h-52">
                                            {menu}
                                        </div>
                                    )}
                                    menu={{
                                        items: currentStagesModi,
                                        onClick: handleChangeHiringStage,
                                    }} >
                                    <img src={!canAccess(updateJobHiringStage) ? getImageUrl('grayArrowDown') : getImageUrl('ChevronDown')} alt='arrow' />
                                </Dropdown>
                            </>
                            : <Tag className='ant-tag-info-ghost tag-lg me-0 min-w-max'>{selectedStageLabel && selectedStageLabel}<img src={getImageUrl('blueCheck')} className='inline ml-2 btn-icon' alt='arrow' /></Tag>
                        }
                    </div>
                </WithTooltip>
            )}
        </>
    )
}

export default ChangeStageActions