
import { createSlice } from "@reduxjs/toolkit"
import { IPipeline, IStageTypes } from "../../../shared/utils/interfaces/pipelineInterface"

const pipelinesSlice = createSlice({
    name: 'pipelines',
    initialState: {
        pipelinesList: [],
        defaultStageTypes: [],
        deleteStageModalOpen: false,
        deletePipelineModalOpen: false,
        activePipelineId: 0,
    },
    reducers: {
        setPipelinesData: (state, action) => {
            state.pipelinesList = action.payload
        },
        setDefaultStageTypes: (state, action) => {
            state.defaultStageTypes = action.payload
        },
        setDeleteStageModalOpen: (state, action: { payload: boolean }) => {
            state.deleteStageModalOpen= action.payload
        },
        setDeletePipelineModalOpen: (state, action: { payload: boolean }) => {
            state.deletePipelineModalOpen= action.payload
        },
        setActivePipelineId: (state, action) => {
            state.activePipelineId = action.payload
        },
    },
})

export const { setPipelinesData, setDefaultStageTypes, setDeleteStageModalOpen,setDeletePipelineModalOpen ,setActivePipelineId} = pipelinesSlice.actions

export default pipelinesSlice.reducer

export const selectPipelines = (state: { pipelines: { pipelinesList: IPipeline[] } }) => state.pipelines.pipelinesList
export const selectDefaultStageTypes = (state: { pipelines: { defaultStageTypes: IStageTypes[] } }) => state.pipelines.defaultStageTypes
export const selectDeleteStageModalOpen = (state: { pipelines: { deleteStageModalOpen: boolean } }) => state.pipelines.deleteStageModalOpen
export const selectDeletePipelineModalOpen = (state: { pipelines: { deletePipelineModalOpen: boolean } }) => state.pipelines.deletePipelineModalOpen
export const selectActivePipelineId = (state: { pipelines: { activePipelineId: number } }) => state.pipelines.activePipelineId