
export const regexPatterns = {
    /**
     * @param {string} mentionPattern - Match example '@\[JohnDoe\](22)'
     */
    mentionPattern: /^@\[\w+\]\(\d+\)/,
    /**
     * @param {string} mentionExceptName - Match '@\[\](22)' in '@\[JohnDoe\](22)' and skip the name inside brackets
     */
    mentionExceptName: /[[@\]()\d]+/g,
    /**
     * @param {string} mentionWithAtSymbol - Match '\[\](22)' in '@\[JohnDoe\](22)' and skip the @ symbol and name inside brackets
     */
    mentionWithAtSymbol: /[[\]()\d]+/g,

    pathWithSlashes: /^.*[\\/]/,
    // Example '/static/media/applyCircleIcon.3f064067945c2b6dc9fa2fd80e592b5b.svg' select all slashes strings to can replace them with empty string

    pathExceptDots: /([^.]+)/, // Match Example 'applyCircleIcon.3f064067945c2b6dc9fa2fd80e592b5b.svg' select all strings before first dot

    /**
     * @param {string} companyUrl - Match example.com only, no https://, www., example.com/page, or any subdomain
     */
    companyUrl: /^([a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*)\.[a-z]{2,}$/,
    cnamePattern: /^[a-zA-Z0-9-]+$/,
    newCompanyUrl: /^\b(?!(https?:\/\/|www\.)\b)(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b(?:\b[-a-zA-Z0-9@:%_.~#?&//=]*)?$/,
    matchAnyUrl: /^((?!\.)(http|https):\/\/)?(?!\.)(www\.)?((?!.*\.{2,})[a-zA-Z0-9-.]+\.[a-zA-Z]{2,6}\/?)(\?([^\s]+)?)?$/,

    matchPlaceholders: /<([^<>]+)>/g,
    emailsWithComma: /^([\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(?:,(?:\s)?([\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}))*$/
}